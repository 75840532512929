import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>57 East 62 Avenue</p>
            </div>
            <div className="d-flex">
              <a href="tel:555-555-555">+1 7783218464</a>
            </div>
            <div className="d-flex">
              <p>anapartybharath33@gmail.com</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
                <Link to="home" offset={-110} className="footer-nav">
                  Home
                </Link>
                <br />
                <Link to="aboutme" offset={-110} className="footer-nav">
                  About me
                </Link>
                <br />
                <Link to="skills" offset={-110} className="footer-nav">
                  Skills
                </Link>
              </div>
              <div className="col">
                <Link to="experience" offset={-110} className="footer-nav">
                  Experience
                </Link>
                <br />
                <Link to="portfolio" offset={-110} className="footer-nav">
                  Portfolio
                </Link>
                <br />
                <Link to="contacts" offset={-100} className="footer-nav">
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              <div
                className="footer-icons mx-3"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/bharadwaja-anaparty-689300100/"
                  )
                }
              >
                <i
                  className="devicon-linkedin-plain colored"
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
              <div
                className="footer-icons mx-3"
                onClick={() => window.open("https://github.com/anaparty33")}
              >
                <i
                  className="devicon-github-original colored"
                  style={{ fontSize: "2rem", color: "white" }}
                ></i>
              </div>
              <div
                className="footer-icons mx-3"
                onClick={() =>
                  window.open("https://www.facebook.com/anaparty.bharath")
                }
              >
                <i
                  className="devicon-facebook-plain colored"
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
            </div>
            <p className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Bharadwaja
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
