import React from "react";

const Skills = () => {
  return (
    <>
      <div id="skills" className="skills">
        <h1 className="py-5">Skills</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="box">
                <h3>Languages</h3>
                <div className="devicon languages container-fluid">
                  <div className="row">
                    <i className="devicon-javascript-plain colored"></i>
                    <i className="devicon-java-plain-wordmark colored"></i>
                    <i className="devicon-python-plain colored"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box">
                <h3>Front-End</h3>

                <div className="devicon frontend container">
                  <div className="row">
                    <i className="devicon-react-original colored"></i>
                    <i className="devicon-html5-plain colored"></i>
                    <i className="devicon-css3-plain-wordmark colored"></i>
                    <i className="devicon-sass-original colored"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box">
                <h3>Back-End</h3>
                <div className="devicon backend container-fluid">
                  <div className="row">
                    <i className="devicon-nodejs-plain colored"></i>
                    <i className="devicon-express-original-wordmark colored"></i>
                    <i className="devicon-mongodb-plain-wordmark colored"></i>
                    <i className="devicon-mysql-plain-wordmark colored"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="box">
                <h3>Tools</h3>
                <div className="devicon tools container-fluid">
                  <div className="row">
                    <i className="devicon-git-plain colored"></i>
                    <i className="devicon-github-original-wordmark colored"></i>
                    <i className="devicon-visualstudio-plain colored"></i>
                    <i className="devicon-amazonwebservices-original colored"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
