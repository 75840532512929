import React from "react";

const Experience = () => {
  return (
    <>
      <div id="experience" className="experience">
        <div className="d-flex justify-content-center my-5">
          <h1>Experience</h1>
        </div>
        <div className="container experience-wrapper">
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2021-Current</h3>
              <p>
                <i
                  className="fas fa-briefcase"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Full Stack Developer, Senior Analyst- Avanade.
                <p className="pt-3">
                  Worked with technlogies{" "}
                  <strong>
                    {" "}
                    JavaScript, TypeScript, React, Redux, Vue.js, Vuex, Java,
                    Spring, Spring Boot
                  </strong>{" "}
                  all the other libraries and technologies related to them.
                </p>
              </p>
              <p>
                I have had the privilege of partnering with esteemed clients
                such as <strong>CIBC </strong>and <strong>LifeWorks.</strong>
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2020-2021</h3>
              <p>
                <i
                  className="fas fa-briefcase"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Full stack Developer, ArcadeQuest.
              </p>
              <p>
                Worked with{" "}
                <strong>
                  {" "}
                  JavaScript, React, Redux, Firebase, AWS, React Native using
                  Expo
                </strong>
              </p>
              <p>
                ArcadeQuest is platform where players can join the streamers and
                play games to win exciting prizes
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2018-2020</h3>
              <p>
                <i
                  className="fas fa-graduation-cap"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Graduted with Masters in Cybersecurity from New York Institute
                Of Technology. Currently looking for job opportunities.
              </p>
            </div>
          </div>
          {/*****/}
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2016-2018</h3>
              <p>
                <i
                  className="fas fa-briefcase"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Got my first job at <strong>Accenture.</strong> I worked as
                Application Development Associate. After working for more than 2
                years I moved on to pursue my masters{" "}
                <i
                  className="fas fa-plane-departure"
                  style={{ fontSize: "1.5rem", padding: "0.325rem" }}
                ></i>{" "}
                in Vancouver, Canada.
              </p>
            </div>
          </div>
          {/*****/}
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2012-2016</h3>
              <p>
                <i
                  className="fas fa-user-graduate"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Bachelors in Computer Science from Chaitanya Bharathi Institute
                Of Technology, India. I got my first job while I was still in
                the last semester of my undergraduate studies..
              </p>
            </div>
          </div>
          {/*****/}
          <div className="timeline-block timeline-block-left">
            <div className="marker"> </div>
            <div className="timeline-content">
              <h3>2012</h3>
              <p>
                <i
                  className="fas fa-book-open"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Graduted High School. This is also the year in which I started
                my undergraduate studies with a major in Computer Science
              </p>
            </div>
          </div>
          {/*****/}
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>1995</h3>
              <p>
                <i
                  className="fas fa-baby"
                  style={{ fontSize: "1.5rem", paddingRight: ".5rem" }}
                ></i>
                Born on May 1, 1995
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
