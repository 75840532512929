import React from "react";
import myImage from "../circle-cropped.jpg";

const Aboutme = () => {
  return (
    <>
      <div id="aboutme" className="container py-5">
        <div className="row">
          <div className="col-lg-6 col-xm-12">
            <div className="photo-wrap mb-5">
              <img
                className="profile-img"
                src={myImage}
                alt="profile img"
                width="400px"
                height="500px"
              ></img>
            </div>
          </div>
          <div className="col-lg-6 col-xm-12">
            <h2 className="about-heading">About Me</h2>
            <p className="about-description">
              I'm Bharadwaja, a versatile full stack web developer with over 5
              years of experience in{" "}
              <strong>
                JavaScript, TypeScript, React, Vue, Vuex, Redux, Node.js, Java,
                Express, Spring
              </strong>{" "}
              and some other cool libraries and frameworks. I specialize in
              building web applications and have expertise in creating CI/CD
              pipelines, Docker containers, and managing Kubernetes clusters on
              Azure and AWS. Let's collaborate to bring your ideas to life with
              cutting-edge technology and seamless execution
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutme;
