import React from "react";
import Experience from "./Experience";
import ecommerce from "../images/ecommerce.png";
import moviesapp from "../images/moviesapp.png";
import portfolio from "../images/portfolio-app.png";
import todo from "../images/To-do app.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

const Portfolio = () => {
  //moviesapp
  const popupMovies = () => {
    const content = (
      <>
        <img className="portfolio-img-popup" src={moviesapp} alt="moviesapp" />
        <p>
          <strong>Movies Application</strong>
        </p>
        <b>Live:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() =>
            window.open("https://serene-austin-d5da14.netlify.app/")
          }
        >
          https://serene-austin-d5da14.netlify.app/
        </a>
        <br />
        <b>Github:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() => window.open("https://github.com/anaparty33/Movieapp")}
        >
          https://github.com/anaparty33/Movieapp
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupMoviesConfig = {
    titleBar: {
      enable: true,
      text: "Movies Application",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  //ecommerce
  const popupEcommerce = () => {
    const content = (
      <>
        <img
          className="portfolio-img-popup"
          src={ecommerce}
          alt="ecommerce application"
        />
        <p>
          <strong>Ecommerce :</strong> MERN e- commerce and implemented full
          featured shopping cart, paypal/ credit card and many more.
        </p>
        <b>Live:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() => window.open("https://anapartystore.herokuapp.com/")}
        >
          https://anapartystore.herokuapp.com/
        </a>
        <br />
        <b>Github:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() => window.open("https://github.com/anaparty33/Ecommerce")}
        >
          https://github.com/anaparty33/Ecommerce
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupEcommerceConfig = {
    titleBar: {
      enable: true,
      text: "",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };

  // portfolio website
  const popupPortfolio = () => {
    const content = (
      <>
        <img
          className="portfolio-img-popup"
          src={portfolio}
          alt="Portfolio application"
        />
        <p>Portfolio website .</p>
        <b>Live:</b>{" "}
        <a className="portofolio-links" onClick={() => window.open("/")}>
          This Aplication
        </a>
        <br />
        <b>Github:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() => window.open("https://github.com/anaparty33/portfolio")}
        >
          https://github.com/anaparty33/portfolio
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupPortfolioConfig = {
    titleBar: {
      enable: true,
      text: "Portfolio Application",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };
  //to-do application
  const popupTodo = () => {
    const content = (
      <>
        <img
          className="portfolio-img-popup"
          src={todo}
          alt="Todo application"
        />
        <p>
          <strong>Todo Application :</strong> Developed with JavaScript,
          Node.js, express.js and deployed database in MongoAtlas with NO SQL
          mongoDB.
        </p>
        <b>Live:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() =>
            window.open("https://desolate-citadel-53349.herokuapp.com/")
          }
        >
          https://desolate-citadel-53349.herokuapp.com/
        </a>
        <br />
        <b>Github:</b>{" "}
        <a
          className="portofolio-links"
          onClick={() => window.open("https://github.com/anaparty33/todo-app")}
        >
          https://github.com/anaparty33/todo-app
        </a>
      </>
    );
    PopupboxManager.open({ content });
  };

  const popupTodoConfig = {
    titleBar: {
      enable: true,
      text: "Todo Application",
    },
    fadeIn: true,
    fadeInSpeed: 500,
  };
  return (
    <>
      <div id="portfolio" className="portfolio-wrapper">
        <div className="container">
          <h1 className="text-uppercase text-center py-5">Portfolio</h1>
          <div className="image-box-wrapper row justify-content-center">
            <div className="portfolio-image-box" onClick={popupEcommerce}>
              <img
                className="portfolio-image"
                src={ecommerce}
                alt="ecommerce application"
              />
              <div className="overflow"></div>
              <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
            </div>

            {/*****/}
            <div className="portfolio-image-box" onClick={popupMovies}>
              <img
                className="portfolio-image"
                src={moviesapp}
                alt="movies application"
              />
              <div className="overflow"></div>
              <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
            </div>

            {/*****/}
            <div className="portfolio-image-box" onClick={popupPortfolio}>
              <img
                className="portfolio-image"
                src={portfolio}
                alt="Portfolio application"
              />
              <div className="overflow"></div>
              <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
            </div>

            {/*****/}

            <div className="portfolio-image-box" onClick={popupTodo}>
              <img
                className="portfolio-image"
                src={todo}
                alt="Todo application"
              />
              <div className="overflow"></div>
              <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
            </div>
          </div>
        </div>
        {/* <PopupboxContainer {...popupPortfolioConfig} />
        <PopupboxContainer {...popupTodoConfig} />
        <PopupboxContainer {...popupMoviesConfig} /> */}
        <PopupboxContainer {...popupEcommerceConfig} />
      </div>
    </>
  );
};

export default Portfolio;
